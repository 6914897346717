import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { podcastTypes } from '../../lib/utils';
import Podcast from '../Podcast/Podcast';
import { colors, maxScreenSize, minScreenSize } from '../../lib/theme';

export default class LandingTrending extends Component {
    constructor(props) {
        super(props);
        this.state = { currIndex: 0 };
    }

    onIndChange = (ind) => {
        this.setState({ currIndex: ind });
    }
    render() {
        const { podcasts } = this.props;
        const { currIndex } = this.state;
        console.dir(podcasts);
        console.dir(currIndex);
        console.dir(podcasts[currIndex]);
        return (
            <div>
                <h2 style={{ textAlign: 'center', marginBottom: '2em' }} className=''>
                    Or select from thousands of other summaries available on Accelerated</h2>
                <div className='main'>
                    <Row>
                        {/* <Col xs={5} md={3} style={{ marginRight: '8px' }}>

                        </Col> */}
                        <Col xs={24} md={24}>
                            <div className='genre-titles'>
                                {podcastTypes.map((podcastType, ind) => (
                                    <div key={podcastType.id} className={`card ${currIndex == ind ? 'selected' : ''}`} onClick={this.onIndChange.bind(this, ind)}>
                                        <h4>{podcastType.name}</h4>
                                    </div>
                                ))}
                            </div>
                            <br />
                            {podcastTypes.map((genrePodcasts, inde) => (
                                <div key={inde} className={`podcast-grid-parent ${currIndex == inde ? 'currPage' : ''}`}>
                                    <div className='podcast-grid'>
                                        {(podcasts[inde] || []).slice(0, 30).map((topPodcast, index) => (
                                            <div key={index} style={{ textAlign: 'center' }}>
                                                {/* {Object.values(topPodcasts).map((topPodcast) => */}
                                                <Podcast className='cards' key={topPodcast.collectionId} podcast={topPodcast} showSubscribe={false} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>
                <style jsx>
                    {`
                        .genre-titles {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                        }
                        .card {
                            background: white;
                            border-radius: 8px;
                            box-shadow: 0 20px 40px 0 rgba(0,11,40,.14);
                            padding: 4px 16px;
                            margin: 12px 0;
                            margin-right: 1em;
                            cursor: pointer;
                        }
                        .selected {
                            background: ${colors.third}
                        }
                        .selected h4 {
                            color: white !important;
                        }
                        .podcast-grid-parent {
                            display: none;
                            // position: absolute;
                            // background: white;
                        }
                        .podcast-grid-parent.currPage {
                            // z-index: 100;
                            display: block;
                        }
                        .podcast-grid {
                            display: flex;
                            flex-wrap: wrap;
                            padding-left: 2em;
                            justify-content: center;
                        }
                        .main {
                            // margin-bottom: 300px;
                        }
                        @media ${maxScreenSize.tablet} {
                            .podcast-grid {
                                padding: 0;
                                padding-left: 1em;
                            }
                            .main {
                                // margin-bottom: 1600px;
                            }
                        }
                        @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
                            .main {
                                // margin-bottom: 1400px;
                            }
                        }
                    `}
                </style>
            </div>
        )
    }
}

LandingTrending.propTypes = {
    podcasts: PropTypes.array,
}
