import { Col, Row } from 'antd'
import React, { Component } from 'react'
import { staticAssetsPrefix } from '../../lib/utils'
import FeatureCard from '../MainPage/FeatureCard'
import { colors, maxScreenSize, minScreenSize } from '../../lib/theme';


const features = [
    { id: 1, title: 'Read or listen to key insights', message: 'Boost your personal growth with 9000+ bestseller summaries.', image: 'booksLarge.png', iconColor: '', iconBg: 'hsla(337, 87%, 80%,1)' },
    { id: 2, title: 'Build better habits', message: 'Spending 15min a day reading can exponentially improve your life over time.', image: 'chart_v5-2.png', iconColor: `${colors.primary}`, iconBg: `${colors.primaryDim}` },
    { id: 3, title: 'Your Personal Coach', message: 'A curated collection of book summaries tailored to your specific goals, helping you stay focused.', image: 'PersonalCoach.png', iconColor: 'hsla(337, 87%, 50%,1)', iconBg: 'hsla(337, 87%, 80%,1)' },
    { id: 4, title: 'Try the Challenges', message: 'Best way to learn is to complete the reading challenges.', image: 'bookmark', iconColor: 'hsla(337, 87%, 50%,1)', iconBg: 'hsla(337, 87%, 80%,1)' },
    { id: 5, title: 'Collect Achievements', message: 'Keep your motivation high with inspiring accomplishments, and take pride in every step you make toward your goal.', image: 'bookmark', iconColor: 'hsla(337, 87%, 50%,1)', iconBg: 'hsla(337, 87%, 80%,1)' },
    { id: 6, title: 'Track your progress', message: 'Stick to your goals and have fun leveling up along the way.', image: 'bookmark', iconColor: 'hsla(337, 87%, 50%,1)', iconBg: 'hsla(337, 87%, 80%,1)' },
];

export default class LandingFeatures extends Component {

    constructor(props) {
        super(props);
        this.state = { isVideoPaused: true };
    }
    componentDidMount() {
        for (const video of document.querySelectorAll('.video-features')) {
            video.controls = false
            video.addEventListener('mouseover', () => { video.controls = 'controls' })
            video.addEventListener('mouseout', () => { video.controls = false })
            video.addEventListener('pause', () => { this.setState({ isVideoPaused: true }) })
            video.addEventListener('play', () => { this.setState({ isVideoPaused: false }) })
        }
    }

    play = () => {
        for (const video of document.querySelectorAll('.video-features')) {
            video.play();
        }
    }

    componentWillUnmount() {
        for (const video of document.querySelectorAll('.video-features')) {
            // video.removeEventListener('mouseover')
            // video.removeEventListener('mouseout')
        }
    }
    render() {
        const { isVideoPaused } = this.state;
        return (
            <div>
                <h2 className='text-center'>Get powerful key ideas <br />in just 15min</h2>
                <div className='feature-card-div'>
                    {/* <h1> Device features</h1> */}
                    {features.map(feature => (
                        <article className='feature-card' key={feature.id}>
                            <FeatureCard key={feature.id} id={feature.id} title={feature.title} message={feature.message}
                                icon={feature.image} iconColor={feature.iconColor} iconBg={feature.iconBg} />
                        </article>
                    ))}
                    {/* <Col md={6} style={{}}>
                        <div style={{ margin: '1.25em auto', textAlign: 'center', position: 'relative' }}>
                            <video className='video-features' width="100%" controls preload="metadata">
                                <source src="https://audio-us.b-cdn.net/Accelerated_new_886_features.mp4#t=0.2" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            {isVideoPaused && <div className='video-overlay'>
                                <img onClick={this.play} style={{ maxWidth: '100%' }} src={`${staticAssetsPrefix}/static/images/play-button.png`} />
                            </div>}
                        </div>
                    </Col> */}
                </div>
                <style jsx>
                    {`
                    .video-features {
                        border-radius: 12px;
                    }
                    .video-overlay {
                        position: absolute;
                        left: calc(50% - 50px);
                        top: calc(50% - 50px);
                        width: 100px;
                    }
                    .feature-card-div {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .feature-card {
                        // display: inline-block;
                        width: 33%;
                        min-height: 200px;
                    }
                    // @media ${minScreenSize.tablet} {
                    @media ${maxScreenSize.tablet} {
                    .feature-card {
                        width: 100% !important;
                    }
                    }
                    `}
                </style>
            </div>
        )
    }
}
